import * as tslib_1 from "tslib";
import { BaseService } from '@src/app/core/base.service';
var AppService = /** @class */ (function (_super) {
    tslib_1.__extends(AppService, _super);
    function AppService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    return AppService;
}(BaseService));
export { AppService };
