import * as tslib_1 from "tslib";
import { Util } from '@src/app/core/util-helper';
import { catchError } from 'rxjs/operators';
var BaseService = /** @class */ (function () {
    function BaseService() {
        var _this = this;
        // error handling helpers
        this.apiErrorCb = function (errData) { return Util.handleError(errData); };
        this.apiErrorHook = catchError(function (errData) { throw _this.apiErrorCb(errData); });
    }
    // deserialisation helpers
    BaseService.prototype.deserializeArray = function (srcArray, instantiator, isBaseModelType) {
        var e_1, _a;
        if (isBaseModelType === void 0) { isBaseModelType = true; }
        if (!srcArray) {
            return undefined;
        }
        if (!instantiator) {
            return srcArray;
        }
        var targetArray = new Array();
        try {
            for (var srcArray_1 = tslib_1.__values(srcArray), srcArray_1_1 = srcArray_1.next(); !srcArray_1_1.done; srcArray_1_1 = srcArray_1.next()) {
                var item = srcArray_1_1.value;
                var instance = (isBaseModelType) ? this.deserialize(item, instantiator()) : instantiator(item);
                targetArray.push(instance);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (srcArray_1_1 && !srcArray_1_1.done && (_a = srcArray_1.return)) _a.call(srcArray_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return targetArray;
    };
    BaseService.prototype.deserialize = function (data, target) {
        target.setValues(data);
        return target;
    };
    BaseService.prototype.hasError = function (errors) { return Object.getOwnPropertyNames(errors).length !== 0; };
    BaseService.prototype.isValid = function (input, errorHandler) {
        var errors = input.validate();
        var hasErrors = this.hasError(errors);
        if (!hasErrors) {
            return true;
        }
        if (errorHandler) {
            errorHandler(errors);
        }
        return false;
    };
    return BaseService;
}());
export { BaseService };
