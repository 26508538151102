import { AppMsg } from '@src/app/core/app-msg';

export class AppError {

  public code: string;
  public msg: string;
  public scope: string;
  public context: string;
  public data: any;

  public constructor(code: string, msg: string, data: any = null) {
    this.code = code;
    this.msg  = msg;
    this.data = data;
    const splits = code.split('_');
    this.scope = splits[0];
    this.context = splits.pop(); // second element if available, else the first element
  }

  public getMessage(): any {
    return this.data ? (JSON.stringify(this.data)) : this.msg;
  }

  public withData(data: any = null) {
    return new AppError(this.code, this.msg, data);
  }

  public toArray(withData: any = null) {
    const errors = new Array<AppError>();
    if (! withData) {
      errors.push(this);
      return errors;
    }
    errors.push(this.withData(withData));
    return errors;
  }

  public apply(to: any) {
    to[this.context] = this.getMessage();
  }

  // TODO move all the error message to AppErrorMsg
  public static unknown = new AppError('app_unknown', 'Unexpected error.');
  public static apiUnknown = AppError.unknown;
  public static api0   = new AppError('app_unreachable', 'Server not available.');
  public static api400 = new AppError('app_badRequest', 'Bad request.');
  public static api401 = new AppError('app_unauthorized', AppMsg.UN_AUTHORIZED);
  public static api403 = new AppError('app_forbidden', 'Operation not allowed.');
  public static api404 = new AppError('app_notFound', 'Operation not found.');
  public static api405 = new AppError('app_methodNotAllowed', 'Method Not allowed.');
  public static api408 = new AppError('app_timedout', 'Request Timeout.');
  public static api500 = new AppError('app_intervalServerError', 'Internal server error.');

  public static oktaTokenInvalid = new AppError('app_oktaInvalid', AppMsg.OKTA_TOKEN_INVALID);
  public static userInvalid = new AppError('app_userInvalid', AppMsg.UN_AUTHORIZED);
}
