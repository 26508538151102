import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/secure/downloadTemplate', pathMatch: 'full' },
  {
    path: 'secure',
    loadChildren: () => import('@src/app/secure/secure.module').then(m => m.SecureModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
