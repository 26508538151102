import * as tslib_1 from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
import { AlertHelper } from '@src/app/core/alert.helper';
import { AppError } from '@src/app/core/app-error.model';
import { AppMsg } from '@src/app/core/app-msg';
import { environment as env } from '@src/environments/environment';
import * as moment from 'moment';
var Util = /** @class */ (function () {
    function Util() {
    }
    Util.newDate = function (fromVal) { return moment(fromVal); };
    Util.deepClone = function (of) { return JSON.parse(JSON.stringify(of)); };
    Util.log = function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        var msg = JSON.stringify(params);
        console.log(msg);
        Util.showErrMessage(msg);
    };
    Util.showErrMessage = function (msg) { AlertHelper.showError(msg); };
    Util.toAppError = function (errorRes) {
        if (errorRes instanceof AppError) {
            return errorRes;
        }
        var errorCode = AppError.apiUnknown;
        if (errorRes instanceof HttpErrorResponse) {
            errorCode = AppError['api' + errorRes.status] || errorCode;
        }
        return errorCode.withData(errorRes);
    };
    Util.handleAppError = function (ae) {
        var errRes = ae.data;
        var userMsg = ae.msg;
        if (errRes instanceof HttpErrorResponse) { // if possible, convert api errorcode to error message
            var error = errRes.error;
            if (error && Array.isArray(error)) {
                var apiErr = error[0];
                userMsg = AppMsg[apiErr.code];
            }
        }
        Util.showErrMessage(userMsg);
        if (env.logErrors) {
            Util.log(userMsg, ae);
        }
    };
    Util.handleError = function (error, unhandledErrors) {
        if (unhandledErrors === void 0) { unhandledErrors = {}; }
        var ae = error;
        if (!ae) {
            ae = AppError.unknown.withData(error);
        }
        if (ae.context === 'app') {
            Util.handleAppError(ae);
        }
        else {
            unhandledErrors[ae.context] = ae.getMessage();
        }
        return unhandledErrors;
    };
    Util.handleErrors = function (errors) {
        var e_1, _a;
        var unhandledErrors = {};
        if (errors.length === 0) {
            return unhandledErrors;
        }
        try {
            for (var errors_1 = tslib_1.__values(errors), errors_1_1 = errors_1.next(); !errors_1_1.done; errors_1_1 = errors_1.next()) {
                var error = errors_1_1.value;
                Util.handleError(error, unhandledErrors);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (errors_1_1 && !errors_1_1.done && (_a = errors_1.return)) _a.call(errors_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return unhandledErrors;
    };
    return Util;
}());
export { Util };
