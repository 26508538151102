import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@src/app/core/base.service';

@Injectable()
export class AppService extends BaseService {

  constructor(
    private http: HttpClient,
    ) { super(); }
}
