import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./secure/secure.module.ngfactory").then(function (m) { return m.SecureModuleNgFactory; }); };
var routes = [
    { path: '', redirectTo: '/secure/downloadTemplate', pathMatch: 'full' },
    {
        path: 'secure',
        loadChildren: ɵ0,
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
