import { BaseModel } from '@src/app/core/base.model';
import { Util } from '@src/app/core/util-helper';
import { catchError } from 'rxjs/operators';

export class BaseService {

  // deserialisation helpers
  public deserializeArray<T>(srcArray: Array<any>, instantiator: any, isBaseModelType = true): Array<T> {
    if (! srcArray) { return undefined; }
    if (! instantiator) { return srcArray; }

    const targetArray = new Array<T>();
    for (const item of srcArray) {
      const instance = (isBaseModelType) ? this.deserialize(item, instantiator()) : instantiator(item);
      targetArray.push(instance);
    }
    return targetArray;
  }

  public deserialize<T extends BaseModel>(data: any, target: T): T {
    target.setValues(data);
    return target;
  }


  // error handling helpers
  public apiErrorCb = (errData: any) => Util.handleError(errData);
  public apiErrorHook = catchError((errData) => { throw this.apiErrorCb(errData); });

  private hasError(errors: any) { return Object.getOwnPropertyNames(errors).length !== 0; }

  public isValid(input: BaseModel, errorHandler: (errors: any) => void): boolean {
    const errors = input.validate();
    const hasErrors = this.hasError(errors);

    if (! hasErrors) { return true; }

    if (errorHandler) { errorHandler(errors); }
    return false;
  }
}
