import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AlertHelper } from '@src/app/core/alert.helper';

@NgModule({
  imports: [ CommonModule ],
  declarations: [],
  exports: [],
  providers: [ AlertHelper ]
})
export class CoreModule {
  public constructor(@SkipSelf() @Optional() coreInstance: CoreModule) {
    if (coreInstance) {
      throw new Error('Core Module - Duplicate Instance found. It has to be imported only in App Module');
    }
  }
}
