import { HttpErrorResponse } from '@angular/common/http';
import { AlertHelper } from '@src/app/core/alert.helper';
import { AppError } from '@src/app/core/app-error.model';
import { AppMsg } from '@src/app/core/app-msg';
import { environment as env } from '@src/environments/environment';
import * as moment from 'moment';

export class Util {

  public static newDate(fromVal: string) { return moment(fromVal); }
  public static deepClone(of: any) { return JSON.parse(JSON.stringify(of)); }

  public static log(...params: any[]) {
    const msg = JSON.stringify(params);
    console.log(msg);
    Util.showErrMessage(msg);
  }

  public static showErrMessage(msg: string) { AlertHelper.showError(msg); }
  public static toAppError(errorRes: any): AppError {

    if (errorRes instanceof AppError) { return errorRes; }

    let errorCode = AppError.apiUnknown;
    if (errorRes instanceof HttpErrorResponse) {
      errorCode = AppError['api' + errorRes.status] || errorCode;
    }
    return errorCode.withData(errorRes);
  }

  private static handleAppError(ae: AppError) {
    const errRes = ae.data;
    let userMsg = ae.msg;

    if (errRes instanceof HttpErrorResponse) { // if possible, convert api errorcode to error message
      const error = errRes.error;
      if (error && Array.isArray(error)) {
        const apiErr = error[0];
        userMsg = AppMsg[apiErr.code];
      }
    }
    Util.showErrMessage(userMsg);
    if (env.logErrors) { Util.log(userMsg, ae); }
  }

  public static handleError(error: any, unhandledErrors = {}) {
    let ae = error as AppError;
    if (! ae) {
      ae = AppError.unknown.withData(error);
    }

    if (ae.context === 'app') {
      Util.handleAppError(ae);
    } else {
      unhandledErrors[ae.context] = ae.getMessage();
    }
    return unhandledErrors;
  }

  public static handleErrors(errors: Array<AppError>) {

    const unhandledErrors = {};
    if (errors.length === 0) { return unhandledErrors; }

    for (const error of errors) {
      Util.handleError(error, unhandledErrors);
    }
    return unhandledErrors;
  }
}
