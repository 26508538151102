import { Injectable } from '@angular/core';
import { AppError } from '@src/app/core/app-error.model';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Injectable()
export class AlertHelper {

  private static AppErrorConfig = { disableTimeOut: true, closeButton: true };
  private static    ErrorConfig = { disableTimeOut: true, closeButton: true };

  private static toastr: ToastrService;
  private static emitter = new Subject<any>();
  public static alertStatus = AlertHelper.emitter.asObservable();


  public constructor(toastr: ToastrService) {
    AlertHelper.toastr = toastr;
  }

  public static showError(msg: string) {
    AlertHelper.toastr.error(msg, 'Error', AlertHelper.ErrorConfig);
  }

  public static showAppError(ae: AppError) {
    AlertHelper.toastr.error(ae.getMessage(), 'Error', AlertHelper.AppErrorConfig);
  }

  public static showWarning(msg: string) {
    AlertHelper.toastr.warning(msg, 'Warning', AlertHelper.AppErrorConfig);
  }

  public static showSuccess(msg: string) {
    AlertHelper.toastr.success(msg);
  }
}
